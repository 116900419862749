$(function () {
  //
  // 上へ戻るボタン
  // ------------------------------
  $("#btn--page-top").hide();//ボタンを非表示にする
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 600) { //ページの上から600pxスクロールした時
      $("#btn--page-top").fadeIn("fast"); //ボタンがフェードインする
    } else {
      $("#btn--page-top").fadeOut("fast");//ボタンがフェードアウトする
    }
  })

  //スムーススクロール設定
  $("#btn--page-top").on("click", function() {
    $("body,html").animate({
      scrollTop: 0
    }, 0);//スムーススクロールの速度
    return false;
  });
});

