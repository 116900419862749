import { gsap } from "gsap";

window.addEventListener('DOMContentLoaded', function() {
  const isFrontPage = document.getElementById('home');
  if (isFrontPage) {
    document.getElementById('loader').classList.add('loaded');
    document.querySelector('body').style.opacity = "0";
    this.window.addEventListener('load',function(){
      fvGsapMove();
    })
  }
});

function isPcSize() {
  const windowWidth = window.innerWidth;
  const breakPointMd = 768;
  const val = (windowWidth > breakPointMd);
  return val;
}

function fvGsapMove() {
  const isPcSizeFlg = isPcSize();
  const gsapObjs = {};
  let objFv01 = '';
  if (isPcSizeFlg) {
    objFv01 = '#pcFvLogo';
  } else {
    objFv01 = '#spFvLogo';
  }
  gsapObjs.obj01 = objFv01;

  //fvアニメーション
  const tl = gsap.timeline();
  tl.to('body', { // body
    opacity: 1.0,
  }).fromTo(gsapObjs.obj01, {
    opacity: 0,
    y: 10,
  }, {
    opacity: 1.0,
    y: 0,
    duration: 2.5,
    ease: 'power2.out'
  }, '>0.5');
}
